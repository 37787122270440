define("discourse/plugins/retort/discourse/services/retort", ["exports", "@glimmer/tracking", "@ember/application", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _application, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let RetortService = _exports.default = (_class = class RetortService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "messageBus", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "picker", _descriptor5, this);
      _initializerDefineProperty(this, "model", _descriptor6, this);
      _initializerDefineProperty(this, "widgets", _descriptor7, this);
    }
    init() {
      super.init(...arguments);
    }
    initBus() {
      if (this.model.id) {
        this.messageBus.unsubscribe(`/retort/topics/${this.model.id}`);
      }
      if (this.currentUser) {
        this.messageBus.subscribe(`/retort/topics/${this.model.id}`, _ref => {
          let {
            id,
            retorts
          } = _ref;
          const post = this.postFor(id);
          if (!post) {
            return;
          }
          post.setProperties({
            retorts
          });
          this.get(`widgets.${id}`).scheduleRerender();
        });
      }
    }
    postFor(id) {
      return (this.model.postStream.posts || []).find(p => p.id === id);
    }
    storeWidget(helper) {
      if (!this.widgets) {
        this.widgets = {};
      }
      this.set(`widgets.${helper.getModel().id}`, helper.widget);
    }
    updateRetort(_ref2, retort) {
      let {
        id
      } = _ref2;
      return (0, _ajax.ajax)(`/retorts/${id}.json`, {
        type: "POST",
        data: {
          retort
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    disabledCategories() {
      const categories = this.siteSettings.retort_disabled_categories.split("|");
      return categories.map(cat => cat.toLowerCase()).filter(Boolean);
    }
    disabledFor(postId) {
      if (!this.currentUser) {
        return true;
      }
      const post = this.postFor(postId);
      if (!post) {
        return true;
      }
      if (!post.topic.details.can_create_post) {
        return true;
      }
      if (post.topic.archived) {
        return true;
      }
      if (!post.topic.category) {
        return false;
      } else {
        const categoryName = post.topic.category.name;
        const disabledCategories = this.disabledCategories();
        return categoryName && disabledCategories.includes(categoryName.toString().toLowerCase());
      }
    }
    openPicker(post) {
      this.controller = (0, _application.getOwner)(this).lookup("controller:topic");
      this.set("picker.isActive", true);
      this.set("picker.post", post);
      this.set("controller.renderRetortPicker", true);
    }
    setPicker(picker) {
      this.set("picker", picker);
      this.picker.emojiSelected = retort => {
        this.updateRetort(this.picker.post, retort).then(() => {
          this.set("picker.isActive", false);
        });
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "messageBus", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "picker", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "widgets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});